<template>
  <div id="forgot">
    <div class="container p-4" style="width: 100%; max-width: 30rem;">
      <h4>Введите почту, на которую был зарегистрирован аккаунт</h4>
      <br />
      <form action="#" method="post" enctype="multipart/form-data">
        <input
          type="email"
          placeholder="Электронная почта"
          id="Email"
          class="log-field form-control-dark mb-4"
          v-model="email"
          style="width: 100%; max-width: 27rem;"
        />

        <input
          type="button"
          class="forgot-btn btn btn-dark btn-block mb-4"
          @click="forgot"
          value="Восстановить пароль"
        />
      </form>

      <p class="text-danger" v-if="msg">{{ msg }}</p>
    </div>

    <div class="ModalWrapper" v-show="sendConfirmationMessage">
      <div class="Modal">
        <div
          class="CloseModalButton"
          @click="sendConfirmationMessage = false"
        ></div>

        <div class="ModalHeader noselect">
          <div class="Buttons">
            <div class="Tab Active">
              <span>Аккаунт не активирован</span>
            </div>
          </div>
        </div>
        <div class="InputWrapper">
          <span class="Label"
            >У вас не активирован аккаунт. <br />Чтобы восстановить пароль,
            нужно сначала подтвердить аккаунт.</span
          >
        </div>

        <div class="Buttons">
          <ButtonGradientBorder
            class="ActionButton"
            ButtonText="Отмена"
            @click.native="sendConfirmationMessage = false"
          />
          <ButtonGradient
            class="ActionButton"
            ButtonText="Отправить письмо активации"
            @click.native="sendActivation"
          />
        </div>
      </div>
    </div>

    <div class="ModalWrapper" v-show="sendConfirmationMessageSuccess">
      <div class="Modal">
        <div
          class="CloseModalButton"
          @click="sendConfirmationMessageSuccess = false"
        ></div>

        <div class="ModalHeader noselect">
          <div class="Buttons">
            <div class="Tab Active">
              <span>Информация</span>
            </div>
          </div>
        </div>
        <div class="InputWrapper">
          <span class="Label"
            >Отлично! Письмо было отправлено. Проверьте свою почту.</span
          >
        </div>

        <div class="Buttons">
          <ButtonGradientBorder
            class="ActionButton"
            ButtonText="Закрыть"
            @click.native="sendConfirmationMessageSuccess = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { forgot, sendActivationForUser } from "@/api/auth.js";

import ButtonGradient from "@/components/CustomElements/ButtonGradient.vue";
import ButtonGradientBorder from "@/components/CustomElements/ButtonGradientBorder.vue";

export default {
  components: {
    ButtonGradient,
    ButtonGradientBorder,
  },
  data() {
    return {
      email: "",
      msg: "",

      sendConfirmationMessage: false,
      sendConfirmationMessageSuccess: false,
    };
  },
  methods: {
    async forgot() {
      try {
        const credentials = {
          email: this.email,
        };
        const response = await forgot(credentials);
        this.$router.push("/auth/reset/mailsent");
      } catch (error) {
        if (error.response.data.message === "Сначала подтвердите email!") {
          this.sendConfirmationMessage = true;
        } else {
          this.msg = error.response.data.message;
        }
      }
    },

    async sendActivation() {
      try {
        await sendActivationForUser({ email: this.email });
        this.sendConfirmationMessageSuccess = true;
      } catch (e) {
        this.msg = error.response.data.message;
      }
      this.sendConfirmationMessage = false;
    },
  },
};
</script>

<style scoped>
.ModalWrapper > .Modal > .Buttons {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;

  width: fit-content;

  gap: 20px;

  margin-left: auto;
  margin-right: auto;
}

.Modal .InputWrapper {
  position: relative;

  width: auto;
  height: fit-content;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
}

.Modal .InputWrapper > .Label {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin: 0% auto;

  color: #e8e7ec;

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 157.143% */
  letter-spacing: -0.28px;
  text-align: center;
}

.Modal > .ModalHeader {
  position: relative;

  padding: 0% 67px;

  width: auto;
  height: auto;

  display: flex;
}
.Modal > .ModalHeader > .Buttons {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;

  width: 100%;
  height: fit-content;

  margin: 0% auto;
}
.ModalHeader > .Buttons > .Tab {
  position: relative;

  cursor: pointer;

  padding: 12px 32px;

  width: fit-content;
  height: fit-content;

  border-bottom: 1px solid rgba(102, 112, 128, 0.3);
}

.ModalHeader > .Buttons > .Tab.Active {
  border-bottom: 2px solid #e8e7ec;
}
.Buttons > .Tab > span {
  position: relative;
  display: block;

  margin: 0% auto;

  color: #667080;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.32px;
  text-align: center;
}
.Buttons > .Tab.Active > span {
  color: #e8e7ec;
  font-weight: 600;
}

.ModalWrapper {
  position: fixed;

  display: flex;

  width: 100%;
  height: 100vh;

  top: 0;
  left: 0;

  margin: 0;
  padding: 0;

  background-color: rgba(0, 0, 0, 0.75);
}

.ModalWrapper > .Modal {
  position: relative;

  padding: 28px 32px;

  width: fit-content;
  max-width: 95%;

  height: fit-content;
  max-height: 95%;

  margin: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;

  border-radius: 20px;
  background: #201e48;
}

.ModalWrapper > .Modal > .CloseModalButton {
  position: absolute;

  cursor: pointer;

  width: 40px;
  height: 40px;

  right: 32px;
  top: 32px;

  background: url("./../../assets/img/Landing/ModalNewCloseButton.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 40px 40px;

  z-index: 10;
}

#forgot {
  position: relative;

  margin-top: 50px;
  font-family: "Montserrat";
  background-color: transparent !important;
  background-image: url("../../assets/img/_landing/karakul2.svg") !important;
  background-position: 100% 135% !important;
  background-attachment: fixed !important;
  background-repeat: no-repeat;
  background-size: 30% !important;
  z-index: 0;
  height: 100vh;
}

h4 {
  font-weight: 600;
  color: #212529;
}

.log-field {
  color: black;
  border: 1px solid #bdbdbd;
  border-radius: 14px !important;
  padding: 10px;
}

.log-field:focus-visible {
  outline: none !important;
}

.forgot-btn {
  text-transform: capitalize;
  color: black;
  padding: 0.75rem 3rem;
  height: auto !important;
  font-size: 18px !important;
  line-height: 28px !important;
  border-radius: 14px !important;
  background-color: #ffcf66;
  font-weight: 600;
}
</style>

<style scoped>
@media (max-width: 480px) {
  #forgot > div > h4 {
    font-size: 1rem;
    text-align: center;
  }

  .ModalWrapper > .Modal > .Buttons {
    flex-direction: column-reverse;
  }
  .ModalWrapper > .Modal > .Buttons > * {
    width: 100%;
  }

  .ModalWrapper > .Modal > .CloseModalButton {
    height: 30px;
    right: 24px;
    top: 24px;
    background-size: 30px 30px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  #forgot > div > h4 {
    font-size: 1rem;
    text-align: justify;
  }
}
</style>
